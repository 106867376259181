.App {
  text-align: center;;
  font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
  padding: 2rem;
}

.App-logo {
  height: 40vmin;
  margin-bottom: 2rem;
  width: 100%;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #232323;
}

.App-link {
  color: #09d3ac;
}

p {
  margin-bottom: .5rem;
}
